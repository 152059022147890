import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';

const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | EZGİCAN Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);
  const [getHtmlDataLongEng, setHtmlDataLongEng] = useState(null);
  const [getHtmlDataLongThird, setHtmlDataLongThird] = useState(null);
  const [getFileName, setFileName] = useState('');
  const [getFileName2, setFileName2] = useState('');
  const [getFileName3, setFileName3] = useState('');
  const [getFileName4, setFileName4] = useState('');
  const [getFileName5, setFileName5] = useState('');
  const [getFileName6, setFileName6] = useState('');

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);





    setIsEdit(true);


    toggle();
  };



  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (getContactEdit && getContactEdit.AboutTitle) || '',
      phone: (getContactEdit && getContactEdit.AboutDesc) || '',
      desc: (getContactEdit && getContactEdit.AboutShortDesc) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Lütfen Başlık Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,

        AboutShortDesc: values.desc,
        AboutDesc: values.phone,

        AboutTitle: values.name,
        AboutImg: getFileName,
        AboutImageTwo: getFileName2,
        AboutBannerImage: getFileName3,
        SSSBannerImage: getFileName4,
        ContactBannerImage: getFileName5,
        BlogBannerImage: getFileName6

      };
      // update order
      dispatch(onUpdateAbout(updateOrder));
      validation.resetForm();

    },
  });
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }
  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAbout());

    }
  }, [dispatch, users]);

  useEffect(() => {
    console.log("nsdlf", users)
    if (users.length > 0) {
      setContactEdit(users[0]);
    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          console.log("lnslkdf", result.file)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }
  const handleChangeStatus2 = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName2(result.file.filename)

        }
      }
    }
  }
  const handleChangeStatus3 = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName3(result.file.filename)

        }
      }
    }
  }
  const handleChangeStatus4 = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName4(result.file.filename)

        }
      }
    }
  }
  const handleChangeStatus5 = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName5(result.file.filename)

        }
      }
    }
  }
  const handleChangeStatus6 = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName6(result.file.filename)

        }
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Hakkımızda Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>







                      <div className="mb-3">
                        <Label htmlFor="productname">Açıklama</Label>
                        <Input
                          id="phone"
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder="Açıklama"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone ? true : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Kısa Açıklama</Label>
                        <Input
                          id="desc"
                          name="desc"
                          type="text"
                          className="form-control"
                          placeholder="Kısa Açıklama"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.desc || ""}
                          invalid={
                            validation.touched.desc && validation.errors.desc ? true : false
                          }
                        />
                        {validation.touched.desc && validation.errors.desc ? (
                          <FormFeedback type="invalid">{validation.errors.desc}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="productname">Başlık</Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Başlık"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name ? true : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Col xs={12} className="mb-3">
                          <label htmlFor="resume">Görsel</label>
                          <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            styles={{
                              dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                              inputLabelWithFiles: { margin: '20px 3%' }
                            }}

                            canRemove={false}
                            PreviewComponent={Preview}
                            accept="image/*"
                          />

                        </Col>
                      </div>

                      <div className="mb-3">
                        <Col xs={12} className="mb-3">
                          <label htmlFor="resume">Görsel 2</label>
                          <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus2}
                            styles={{
                              dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                              inputLabelWithFiles: { margin: '20px 3%' }
                            }}

                            canRemove={false}
                            PreviewComponent={Preview}
                            accept="image/*"
                          />

                        </Col>
                      </div>
                      <div className="mb-3">
                        <Col xs={12} className="mb-3">
                          <label htmlFor="resume">Hakkımızda Banner Görsel</label>
                          <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus3}
                            styles={{
                              dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                              inputLabelWithFiles: { margin: '20px 3%' }
                            }}

                            canRemove={false}
                            PreviewComponent={Preview}
                            accept="image/*"
                          />

                        </Col>
                      </div>
                      <div className="mb-3">
                        <Col xs={12} className="mb-3">
                          <label htmlFor="resume">SSS Banner Görsel</label>
                          <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus4}
                            styles={{
                              dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                              inputLabelWithFiles: { margin: '20px 3%' }
                            }}

                            canRemove={false}
                            PreviewComponent={Preview}
                            accept="image/*"
                          />

                        </Col>
                      </div>

                      <div className="mb-3">
                        <Col xs={12} className="mb-3">
                          <label htmlFor="resume">iletişim Banner Görsel</label>
                          <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus5}
                            styles={{
                              dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                              inputLabelWithFiles: { margin: '20px 3%' }
                            }}

                            canRemove={false}
                            PreviewComponent={Preview}
                            accept="image/*"
                          />

                        </Col>
                      </div>
                      <div className="mb-3">
                        <Col xs={12} className="mb-3">
                          <label htmlFor="resume">Blog Banner Görsel</label>
                          <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus6}
                            styles={{
                              dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                              inputLabelWithFiles: { margin: '20px 3%' }
                            }}

                            canRemove={false}
                            PreviewComponent={Preview}
                            accept="image/*"
                          />

                        </Col>
                      </div>
                    




                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
